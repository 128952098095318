<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      :width="drawerWidth()"
      style="padding-top: 90px;"
    >

      <v-list>
        <v-list-item
          :ripple="false"
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          @click.prevent="checkDrawer"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav header-one-page-nav-two"
    >
      <div class="hidden-md-and-up">
        <v-btn
          :class="{ 'is-active': drawer }"
          class="hamburger hamburger--spin mobile-menu"
          style="justify-content: left;padding: 0;color: #00000000;margin: 0;"
          text
          icon
          @click.prevent="toggle"
        >
          <span class="hamburger-box">
            <span class="bar hamburger-inner" />
          </span>
        </v-btn>
      </div>
      <router-link to="/" class="logo">
        <img width="50px" src="../assets/images/logo/jc_logo.png" />
      </router-link>
      <!-- End brand logo -->

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
        >
          <v-btn
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >{{ item.title }}</v-btn
          >
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-spacer></v-spacer>
      <div class="social-share-inner">
        <socialTwo />
      </div>
      <span class="divider" />
      <div class="themeSwitch" @click="switchTheme">
        <div class="sun sun-logo" :class="sunClass">
          <i class="fas fa-sun"></i>
        </div>
        <div class="moon moon-logo" :class="moonClass">
          <i class="fas fa-moon"></i>
        </div>
      </div>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  import socialTwo from "./social/SocialTwo";
  export default {
    components: {
      socialTwo,
    },
    data: () => ({
      themeIcon: 'sun',
      isOpen: false,
      drawer: false,
      darkMode: false,
      items: [
        { title: "Home", to: "#home" },
        { title: "About", to: "#about" },
        { title: "Service", to: "#service" },
        { title: "Portfolio", to: "#portfolio" },
        { title: "Blog", to: "#blog" },
        { title: "Contact", to: "#contact" },
      ],
      icon: "menu",
      closeIcon: "x",
      sunClass: "",
      moonClass: "",
    }),
    methods: {
      checkDrawer() {
        this.isOpen = this.drawer
      },
      toggle() {
        this.isOpen = !this.isOpen
        this.drawer = !this.drawer
      },
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      switchTheme() {
        if (this.themeIcon === 'sun') {
          this.themeIcon = 'moon'
          this.$themeMode = 'active-light'
        } else {
          this.themeIcon = 'sun'
          this.$themeMode = 'active-dark'
        }
        localStorage.setItem('themeMode', this.$themeMode);
        this.$emit('theme-mode-change', this.$themeMode);

        document.querySelector(".sun-logo").classList.toggle("animate-sun");
        document.querySelector(".moon-logo").classList.toggle("animate-moon");
      },
      init() {
        this.$themeMode = localStorage.getItem('themeMode')
        if (this.$themeMode === 'active-light') {
          this.themeIcon = 'moon'
          this.moonClass = 'animate-moon'
          this.sunClass = 'animate-sun'
        }
      },
      drawerWidth() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '32%'
          case 'md': return '30%'
          case 'lg': return '30%'
          case 'xl': return '30%'
        } 
      }
    },
    mounted() {
      this.init()
    }
  };
</script>

<style scoped>

</style>
