<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Contact Me</h2>
        <p class="description">
          Please fill out this form with as much information about your project as possible. 
          I usually call or text within 24 hours.
        </p>
        <span v-if="!showForm" class="form-submitted">
          Your information has been sent! I will get in touch with you shortly!
        </span>
      </div>
      <div class="form-wrapper" v-if="showForm">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Your Name *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Your Email *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="phone"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.phone"
                  @input="acceptNumber()"
                  placeholder="Your Phone *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>
            
            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea
                  v-model="formData.message"
                  placeholder="Your Message *"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <img src="../../assets/images/about/code-laptop.jpg" alt="trydo" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import axios from 'axios';
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        apiUrl: 'https://api.airtable.com/v0/',
        apiKey: 'patJp8DZnCN4wBADT.b73ccae1c4315a4babb4261bed639b00ff64e76c008997384f255d69d6883efe', // Always use a read-only account token
        base: 'appGUxsG36fBZVXmN/Form Submissions',
        showForm: true,
        formData: {
          name: "",
          email: "",
          phone: "",
          message: "",
          referral: ""
        },
      };
    },
    methods: {
      onSubmit() {
        this.formData.referral = localStorage.getItem('referral')
        this.postData()
      },
      acceptNumber() {
        var x = this.formData.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.formData.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      },
      postData() {

        let axiosConfig = {
          headers: {
              'Authorization': 'Bearer ' + this.apiKey,
              'Content-Type': 'application/json'
          }
        };

        const data = {
          "records": [
            {
              "fields": this.formData
            }
          ]
        };

        let url = this.apiUrl + this.base

        axios.post(url, data, axiosConfig).then((res) => {
          console.log(res)
          this.showForm = false
        });
      }
    },
  };
</script>
