<template>
  <div class="active-dark" style="height: 100vh">
    <div class="error-page-inner bg_color--4" style="height: 100vh">
      <v-container style="height: 100vh">
        <v-row  v-if="this.showString" style="height: 100vh">
          <v-col cols="12" style="margin-top: auto; margin-bottom: auto">
            <img src="../assets/images/logo/jclg1.gif" width="80px" style="margin: auto; margin-top: 20px;"/>
            <img src="../assets/images/email/fb.png" />
            <img src="../assets/images/email/it.png" />
            <img src="../assets/images/email/ln.png" />
            <img src="../assets/images/email/banner.png" />
            <div class="message" style="height: 100%">
              <h1 class="heading-welcome" style="font-size: 32px; margin-top: 20px; text-align: center;">My website is currently under construction, but you can send me a message to get in touch.</h1>
                
                <div class="view-more-btn mt--60 mt_sm--30 text-center">
                  <transition name="fade">
                    <a class="rn-button-style--2 btn_solid" href="mailto:jake@jakecreate.com" >Contact Jake</a>
                  </transition>
                </div>
                
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import Header from "../components/Header";
  import FooterTwo from "../components/footer/FooterTwo";
  export default {
    components: {
      Header,
      FooterTwo,
    },
    data() {
      return {
        showBtn: false,
        showString: true,
        stringCount: 0,
        string0: ["Hey there, my website is currently under construction, but you can send me message to get in touch."],
        string1: ["My name is Jake Rodriguez, and I\'m a Full-Stack Developer!"],
        string2: ["I am going to ask you just a few questions to get things set up on my end."],
        string3: ["Are you ready to discover the most <span class=\'fancy\'>innovative</span> solutions known to the web?"]
      };
    },

    methods: {
      typingComplete() {
        console.log('Done')
      },
      showContinue() {
        this.showBtn = true
      },
      nextString() {
        this.stringCount += 1
        this.showString = false
        this.showBtn = false
        if (this.stringCount < 4) {
          setTimeout(() => { this.showString = true }, 100);
        }
      },
      openText() {

      }
    }
  };
</script>
