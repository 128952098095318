<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="6"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in records"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <a class="router-link-exact-active router-link-active">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.fields.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.fields.title }}</h3>
              <p>
                {{ service.fields.desc }}
              </p>
            </div>
          </div>
        </a>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  import axios from 'axios';
  export default {
    data() {
      return {
        apiUrl: 'https://api.airtable.com/v0/',
        apiKey: 'patJp8DZnCN4wBADT.b73ccae1c4315a4babb4261bed639b00ff64e76c008997384f255d69d6883efe', // Always use a read-only account token
        base: 'appGUxsG36fBZVXmN/Services?',
        filter: 'sort%5B0%5D%5Bfield%5D=order_num&sort%5B0%5D%5Bdirection%5D=asc',
        records: [],
        serviceContent: [
          {
            icon: "cast",
            title: "Business Stratagy",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "layers",
            title: "Website Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "users",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "monitor",
            title: "Mobile Development",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "users",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "monitor",
            title: "Mobile Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
        ],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      getData() {
        let airUrl = this.apiUrl + this.base
        axios({
          url: airUrl + this.filter,
          headers: {
            'Authorization': `Bearer ${this.apiKey}`
          }
        }).then((res) => {
          this.records = res.data.records;
        });
      }
    },
  };
</script>
