<template>
  <v-row v-if="isLoaded">
    <!-- Start Blog Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in blogContent"
      :key="i"
    >
      <div class="blog blog-style--1">
        <div v-if="item.image" class="thumbnail">
          <a :href="item.url" target="_blank">
            <img style="height: 260px; object-fit: cover;" class="w-100" :src="item.image.contentUrl" @error="imgError(i)" alt="Blog Images" />
          </a>
        </div>
        <div class="content">
          <p class="blogtype">{{ item.provider[0].name }}</p>
          <h4 class="heading-title" style="overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis;">
            <a :href="item.url" target="_blank">{{ item.name }}</a>
          </h4>
          <div class="blog-btn">
            <a :href="item.url" target="_blank" class="rn-btn white--text">
              Read More
            </a>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
import axios from 'axios'

  export default {
    data() {
      return {
        blogContent: [],
        isLoaded: false
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        var options = {
          method: 'GET',
          url: 'https://bing-news-search1.p.rapidapi.com/news/search',
          params: {
            q: 'AI,technology',
            originalImg: 'true'
          },
          headers: {
            'X-BingApis-SDK': 'true',
            'x-rapidapi-key': '362d3adeccmsh403373b3368cd6fp17a8f8jsn8b8eb063ce37',
            'x-rapidapi-host': 'bing-news-search1.p.rapidapi.com'
          }
        };
        let app = this

        axios.request(options).then(function (response) {
          let data = response.data.value;
          console.log(data)
          app.blogContent = data
          app.isLoaded = true
          app.filterNews()
        }).catch(function (error) {
          console.error(error);
        });
      },
      filterNews() {
        this.blogContent = this.blogContent.filter(this.checkForImg)
      },
      checkForImg(item) {
        if (item.image) {
          return true
        } else {
          return false
        }
      },
      imgError(i) {
        this.blogContent.splice(i, 1)
      }
    }
  };
</script>
