<template>
  <div class="active-dark">
    <div class="error-page-inner bg_color--4">
      <v-container>
        <v-row  v-if="this.showString">
          <v-col cols="12">
            <div class="message">
                <vue-typed-js :strings="curString()"
                              :typeSpeed="40"
                              :fadeOut="true"
                              :loop="false"
                              :showCursor="false"
                              :fadeOutDelay="1000"
                              @onStringTyped="showContinue()"
                              @onComplete="typingComplete()"
                              >
                  <h1 class="heading-welcome"><span class="typing"></span></h1>
                </vue-typed-js>
                
                <div v-if="this.showBtn" class="view-more-btn mt--60 mt_sm--30 text-center">
                  <transition name="fade">
                    <a class="rn-button-style--2 btn_solid" href="#" @click="nextString()" >Continue</a>
                  </transition>
                </div>
                
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import Header from "../components/Header";
  import FooterTwo from "../components/footer/FooterTwo";
  export default {
    components: {
      Header,
      FooterTwo,
    },
    data() {
      return {
        showBtn: false,
        showString: true,
        stringCount: 0,
        string0: ["Hey there, welcome to my website!"],
        string1: ["My name is Jake Rodriguez, and I\'m a Full-Stack Developer!"],
        string2: ["I am going to ask you just a few questions to get things set up on my end."],
        string3: ["Are you ready to discover the most <span class=\'fancy\'>innovative</span> solutions known to the web?"]
      };
    },

    methods: {
      typingComplete() {
        console.log('Done')
      },
      showContinue() {
        this.showBtn = true
      },
      nextString() {
        this.stringCount += 1
        this.showString = false
        this.showBtn = false
        if (this.stringCount < 4) {
          setTimeout(() => { this.showString = true }, 100);
        }
      },
      curString() {
        switch (this.stringCount) {
          case 0:
            return this.string0
          case 1:
            return this.string1
          case 2:
            return this.string2
          case 3:
            return this.string3
        }
      }
    }
  };
</script>
