<template>
  <div :class="elTheme">
    <!-- Start Header Area -->
    <Header @theme-mode-change="themeModeChange" />
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-slider-area poss_relative" id="home">
      <div
        class="slide personal-portfolio-slider slider-style-3 slider-paralax d-flex align-center"
      >
        <BannerThree>
          <span slot="designation" style="padding-left: 6px;">JAKE CREATE</span>
          <h1 class="heading-title" slot="heading-title">
            <vue-typed-js :strings="['Create a Legacy.', 'Build an App.', 'Increase sales.', 'Design a Website.']" :loop="true" :typeSpeed="60">
              <h1 class="heading-title">Don't just <br>build a brand,<br> <span class="typing"></span></h1>
            </vue-typed-js>
          </h1>
          <div class="particles" slot="particles">
            <vue-particles
              color="#b8860b"
              :particleOpacity="0.9"
              :particlesNumber="150"
              shapeType="circle"
              :particleSize="3"
              linesColor="#b8860b"
              :linesWidth="1"
              :lineLinked="true"
              :lineOpacity="0.7"
              :linesDistance="120"
              :moveSpeed="4"
              :hoverEffect="true"
              hoverMode="grab"
              :clickEffect="true"
              clickMode="push"
              outMode="bounce"
            >
            </vue-particles>
          </div>
        </BannerThree>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="section about-area rn-section-gap bg_color--1" id="about">
      <div class="about-wrapper">
        <div class="container">
          <AboutFour>
            <img
              slot="thum-img"
              class="w-100"
              src="../assets/images/about/jake-coding.jpg"
              alt="About Images"
            />
          </AboutFour>
        </div>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Service Area  -->
    <div
      class="section service-area rn-service-area rn-section-gap bg_color--5"
      id="service"
    >
      <div class="container">
        <v-row>
          <v-col lg="12">
            <div
              class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0"
            >
              <h2 class="heading-title">My Awesome Service</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </div>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Portfolio Area -->
    <div
      class="section rn-portfolio-area rn-section-gap bg_color--1"
      id="portfolio"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title section-title--2 text-center mb--20">
              <h2 class="heading-title">My Latest Projects</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
        <portfolio-three />
        <v-row>
          <v-col lg="12">
            <div class="view-more-btn mt--60 mt_sm--30 text-center">
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Blog Area  -->
    <div class="section rn-blog-area rn-section-gap bg_color--5" id="blog">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="12">
            <div class="section-title text-left">
              <h2>Latest News</h2>
              <p>
                Stay updated with the latest news and trends in technology. 
              </p>
            </div>
          </v-col>
        </v-row>
        <Blog />
      </v-container>
    </div>
    <!-- End Blog Area  -->

    <!-- Start Contact Area  -->
    <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../assets/images/about/code-laptop.jpg"
              alt="trydo"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../components/Header";
  import BannerThree from "../components/slider/BannerThree";
  import AboutFour from "../components/about/AboutFour";
  import ServiceThree from "../components/service/ServiceThree";
  import PortfolioFour from "../components/portfolio/PortfolioFour";
  import PortfolioThree from "../components/portfolio/PortfolioThree";
  import Blog from "../components/blog/Blog";
  import Contact from "../components/contact/Contact";
  import Footer from "../components/footer/FooterTwo";

  export default {
    components: {
      Header,
      BannerThree,
      AboutFour,
      ServiceThree,
      PortfolioFour,
      Blog,
      Contact,
      Footer,
      PortfolioThree,
    },
    data() {
      return {
        elTheme : 'active-dark'
      };
    },
    methods: {
      setTheme() {
        let loStore = localStorage.getItem('themeMode');
        if(loStore) {
          this.$themeMode = loStore;
        } else {
          this.$themeMode = 'active-dark';
          localStorage.setItem('themeMode', this.$themeMode);
        }
        this.elTheme = this.$themeMode
      },
      themeModeChange(newValue) {
        this.elTheme = newValue
      }
    },
    mounted() {
      this.setTheme()
    },
    watch: {
      $themeMode: function() {
        console.log("theme change")
      }
    }
  };
</script>
