<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(item, i) in item.content" :key="i">
                    <a class="router-link-active"
                      >{{ item.title }}
                      <span> - {{ item.name }}</span></a
                    >
                    {{ item.desc }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Skills",
          },
          {
            id: 2,
            name: "Tools",
          },
          {
            id: 3,
            name: "Experience",
          },
          {
            id: 4,
            name: "Education",
          },
        ],

        tabContent: [
          {
            id: 1,
            content: [
              {
                title: "Problem Solving",
                name: "Ability to solve high-level modern problems using the latest languages & frameworks.",
                desc: "",
              },
              {
                title: "Vision & Design",
                name: "I create stunning UX/UI's with accuracy and attention to every detail.",
                desc: "",
              },
              {
                title: "Project Management",
                name: "No projects of mine go without organization, time management, and communication.",
                desc: "",
              },
              {
                title: "Mathematical Aptitude",
                name: "High level of mathematical intellect with a quickness to learn and solve problems.",
                desc: "",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                title: "Frameworks",
                name: "Web & App Development",
                desc: "Vue.js, Laravel, Express.js, CakePHP, Bootstrap, Angular, React",
              },
              {
                title: "Databases",
                name: "Data Storage & Analytics",
                desc: "MySQL, PostgreSQL, MongoDB, MariaDB, & More",
              },
              {
                title: "Design",
                name: "UX/UI & Branding",
                desc: "HTML & CSS, Affinity Suite, Adobe Suite, Sketch",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                title: "2011 - Current",
                name: "Writing programs for small robots, network devices, and smart home electronics.",
                desc: "",
              },
              {
                title: "2015 - 2017",
                name: "Created Businesses & E-Commerce websites while competing in programming competitions.",
                desc: "",
              },
              {
                title: "2018 - Current",
                name: "Providing development, marketing, tech support, and designing services for companies and individuals",
                desc: "",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                title: "Computer Science AP 1 & 2",
                name: "High School",
                desc: "Mastered the fundamentals and history of computers and software development.",
              },
              {
                title: "Robotics AP",
                name: "Gifted & Talented Academy",
                desc: "Studied linear math and technology disciplines including machine learning, artificial intelligence, and data science.",
              },
              {
                title: "Web Development",
                name: "Self Taught",
                desc: "Ongoing self-development in the latest languages, frameworks, and techniques.",
              },
            ],
          },
        ],
      };
    },
  };
</script>
