<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in records"
      :key="i"
    >
      <div class="portfolio">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="item.fields['Desktop Img'][0].url"></v-img>
          <v-img class="bg-blr-image" :src="item.fields['Desktop Img'][0].url"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ item.fields.Type }}</p>
            <h4>
              <router-link to="portfolio-details">{{ item.fields.Name }}</router-link>
            </h4>
            <div class="portfolio-button">
              <router-link class="rn-btn" to="/portfolio-details"
                >View Project</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        apiUrl: 'https://api.airtable.com/v0/',
        apiKey: 'patJp8DZnCN4wBADT.b73ccae1c4315a4babb4261bed639b00ff64e76c008997384f255d69d6883efe', // Always use a read-only account token
        base: 'appGUxsG36fBZVXmN/Portfolio',
        records: [],
        portfolioContent: [
          {
            src: require("../../assets/images/portfolio/portfolio-8.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
          {
            src: require("../../assets/images/portfolio/portfolio-7.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
          {
            src: require("../../assets/images/portfolio/portfolio-3.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
          {
            src: require("../../assets/images/portfolio/portfolio-4.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
          {
            src: require("../../assets/images/portfolio/portfolio-3.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
          {
            src: require("../../assets/images/portfolio/portfolio-2.jpg"),
            meta: "Development",
            title: "Getting tickets to the big show",
          },
        ],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        axios({
          url: this.apiUrl + this.base,
          headers: {
            'Authorization': `Bearer ${this.apiKey}`
          }
        }).then((res) => {
          this.records = res.data.records;
        });
      }
    }
  };
</script>
