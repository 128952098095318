<template>
  <div>
    <v-tabs v-model="tab" centered flat color="primary" style="margin-top: 28px">
      <v-tab :ripple="false" v-for="(item, itemIndex) in tabItems" :key="itemIndex">{{
        item
      }}s</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(items, g) in tabContent" :key="g">
        <CoolLightBox
          :items="items"
          :index="index"
          :fullScreen="true"
          :slideshow="false"
          :effect="'fade'"
          @close="index = null"
        >
        </CoolLightBox>
        <div
          class="gallery-wrapper gallery-grid mesonry-list grid-metro3"
          id="animated-thumbnials"
        >
          <div
            v-for="(item, i) in items"
            :key="i"
            @click="index = i"
            class="image masonry_item portfolio-33-33"
          >
            <div class="item-portfolio-static ">
              <div class="portfolio-static">
                <div class="thumbnail-inner">
                  <div class="thumbnail">
                    <img :src="item['Desktop Img'][0].url" alt="portfolio image" />
                  </div>
                </div>

                <div class="content">
                  <div class="inner">
                    <p>{{ item.Type }}</p>
                    <h4>{{ item.Name }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        apiUrl: 'https://api.airtable.com/v0/',
        apiKey: 'patJp8DZnCN4wBADT.b73ccae1c4315a4babb4261bed639b00ff64e76c008997384f255d69d6883efe', // Always use a read-only account token
        base: 'appGUxsG36fBZVXmN/Portfolio',
        records: [],
        tab: null,
        index: null,
        tabItems: [],
        tabContent: [],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        axios({
          url: this.apiUrl + this.base,
          headers: {
            'Authorization': `Bearer ${this.apiKey}`
          }
        }).then((res) => {
          this.records = res.data.records;
          this.formatTabs()
        });
      },
      formatTabs() {
        let fieldsOnly = this.uniqueKeyValues(this.records, 'fields')
        this.tabItems = this.uniqueKeyValues(fieldsOnly, 'Type')
        this.tabItems.unshift('All Project')
        this.groupContent()
      },
      groupContent() {
        let tabItems = this.tabItems
        let records = this.records
        let newContent = []

        for (let i = 0; i < tabItems.length; i++) {
          let newList = []
          for (let r = 0; r < records.length; r++) {
            if (records[r].fields.Type === tabItems[i] || tabItems[i] === "All Project") {
              let recordFields = records[r].fields
              recordFields.src = records[r].fields['Desktop Img'][0].url
              recordFields.title = records[r].fields.Name
              newList.push(records[r].fields)
            }
          }
          newContent.push(newList)
        }
        this.tabContent = newContent
      },
      uniqueKeyValues(arr, key) {
        return [... new Set(arr.map((obj) => {return obj[key]}))];
      }
    }
  };
</script>
