import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import Maintenance from '../views/Maintenance.vue'


Vue.use(VueRouter)

const normRoutes = [{
        path: '/',
        component: Home,
        meta: {
            title: 'Jake Create - Full-Stack Developer'
        }
    },
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: 'Service - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/service/Service.vue')
    },
    {
        path: '/service-details',
        name: 'ServiceDetails',
        meta: {
            title: 'Service Details - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/service/ServiceDetails.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            title: 'Blog - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/blog/Blog.vue')
    },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        meta: {
            title: 'Blog Details - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/blog/BlogDetails.vue')
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        meta: {
            title: 'Portfolio - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/portfolio/Portfolio.vue')
    },
    {
        path: '/portfolio-details',
        name: 'PortfolioDetails',
        meta: {
            title: 'Portfolio Details - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/portfolio/PortfolioDetails.vue')
    },
    {
        path: '/pricing-plan',
        name: 'PricingPlan',
        meta: {
            title: 'Pricing Plan - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/PricingPlan.vue')
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        meta: {
            title: 'Tesimonial - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/Testimonial.vue')
    },
    {
        path: '/gallery',
        name: 'Gallery',
        meta: {
            title: 'Gallery - Jake Create - Full-Stack Developer'
        },
        component: () =>
            import ('../views/Gallery.vue')
    },
    {
        path: '/welcome',
        component: Welcome,
        meta: {
            title: 'Welcome - Jake Create - Full-Stack Developer'
        }
    },
    {
        path: '/maintenance',
        component: Maintenance,
        meta: {
            title: 'Maintenance - Jake Create - Full-Stack Developer'
        }
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 - Jake Create - Full-Stack Developer'
        },
    },
    {
        path: '/404-page',
        component: Missing,
        meta: {
            title: '404 - Jake Create - Full-Stack Developer'
        },
    }
]

const maintRoutes = [{
    path: '/',
    component: Maintenance,
    meta: {
        title: 'Jake Create - Full-Stack Developer'
    }
},
{
    path: '*',
    component: Maintenance,
    meta: {
        title: 'Jake Create - Full-Stack Developer'
    },
}
]


let maintMode = false
let domainName = window.location.hostname
let routes = normRoutes

if (maintMode && (domainName === 'jakecreate.com')) {
    routes = maintRoutes
} 

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router